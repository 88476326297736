<template>
  <div class="page">
    <Navbar title="微客购买" type="SMP" />
    <van-radio-group
      v-model="durationRadio"
      @change="durationChange"
      :checked-color="COLOR_M"
    >
      <van-row class="duration">
        <van-col span="4"
          ><van-tag type="primary" :color="COLOR_M">体验套餐</van-tag
          ><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-vke"></use></svg
        ></van-col>
        <van-col span="11">
          <div class="name">3天微客特权</div>
          <div class="desc text-short">可享推广分成、邀请奖励等特权</div>
        </van-col>
        <van-col span="7"
          ><div class="price color-s1">￥1元</div>
          <div class="old-price">￥1元</div></van-col
        >
        <van-col span="2"
          ><div class="radio">
            <van-radio name="1"></van-radio></div
        ></van-col>
      </van-row>
      <van-row class="duration">
        <van-col span="4"
          ><van-tag type="primary" :color="COLOR_S1">限时特惠</van-tag>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-vke"></use></svg
        ></van-col>
        <van-col span="11">
          <div class="name">1年微客特权</div>
          <div class="desc text-short">可享推广分成、邀请奖励等特权</div>
        </van-col>
        <van-col span="7"
          ><div class="price color-s1">￥1980元(1折)</div>
          <div class="old-price">￥198000元</div></van-col
        >
        <van-col span="2"
          ><div class="radio">
            <van-radio name="2"></van-radio></div
        ></van-col>
      </van-row>

      <van-row class="duration">
        <van-col span="4"
          ><van-tag type="primary" :color="COLOR_M">热门套餐</van-tag
          ><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-vke"></use></svg
        ></van-col>
        <van-col span="11">
          <div class="name">6个月微客特权</div>
          <div class="desc text-short">可享推广分成、邀请奖励等特权</div>
        </van-col>
        <van-col span="7"
          ><div class="price color-s1">￥8910元(7.5折)</div>
          <div class="old-price">￥11880元</div></van-col
        >
        <van-col span="2"
          ><div class="radio">
            <van-radio name="3"></van-radio></div
        ></van-col>
      </van-row>
    </van-radio-group>

    <van-row class="title">
      <van-col span="12" class="text"
        ><van-icon name="notes-o" /> 支付方式</van-col
      >
    </van-row>
    <van-radio-group v-model="payRadio" :checked-color="COLOR_M">
      <van-row class="pay">
        <van-col span="12">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-wpay"></use>
          </svg>
        </van-col>
        <van-col span="10"
          ><div class="price color-s1">总金额:￥{{ amount }}元</div></van-col
        >
        <van-col span="2"
          ><div class="radio">
            <van-radio name="wechat"></van-radio></div
        ></van-col>
      </van-row>
    </van-radio-group>
    <van-row class="button">
      <van-col offset="7" span="10">
        <van-button
          icon="cart-circle-o"
          @click="payment"
          size="small"
          :color="COLOR_M"
          block
          >立即购买</van-button
        >
      </van-col>
    </van-row>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import Navbar from '../../module/common/Navbar.vue'
import Share from '../../module/common/Share.vue'
import Loading from '../../module/common/Loading.vue'
import { RadioGroup, Radio, Tag, Icon } from 'vant'
import '../../../assets/icon/font-icon.js'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Tag.name]: Tag,
    [Icon.name]: Icon
  },
  data () {
    return {
      loadingShow: false,
      durationRadio: '2',
      payRadio: 'wechat',
      payOrderCode: '',
      amount: 1980,
      duration: { mode: 'MOT', count: 12 },
      count: 365
    }
  },
  mounted () {
    this.$refs.share.default('seller')
  },
  methods: {
    durationChange (val) {
      if (val === '1') {
        this.duration.count = 3
        this.duration.mode = 'DAY'
        this.amount = 1
      } else if (val === '2') {
        this.duration.count = 12
        this.duration.mode = 'MOT'
        this.amount = 1980
      } else if (val === '3') {
        this.duration.count = 6
        this.duration.mode = 'MOT'
        this.amount = 8910
      }
    },
    async payment () {
      var code = window.sessionStorage.getItem(this.SESSION_USER)
      this.loadingShow = true
      var serial = window.sessionStorage.getItem(this.SESSION_SERIAL)
      var pd = {
        appid: 'wxa8145a74e5f2b39a',
        amount: this.amount * 100,
        attach: '',
        description: '微客特权购买支付',
        body: '微客特权购买支付',
        detail: '',
        business: code,
        openid: serial
      }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/createJsapiPay', this.$qs.stringify(pd))
      if (res.status === '200') {
        var that = this
        this.payOrderCode = res.data.code
        var param = res.data.param
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', param,
          function (rs) {
            if (rs.err_msg === 'get_brand_wcpay_request:ok') {
              that.retrievePayStatus()
            } else {
              that.loadingShow = false
            }
          })
      }
    },
    async retrievePayStatus () {
      var pd = { appid: 'wxa8145a74e5f2b39a', code: this.payOrderCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/pay/retrievePayStatus', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$dialog.alert({
          title: '提示信息',
          message: '支付成功'
        })
        this.sellerDurationBuy()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '支付失败'
        })
      }
      this.loadingShow = false
    },
    async sellerDurationBuy () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: seller, amount: this.amount, mode: this.duration.mode, count: this.duration.count }
      const { data: res } = await this.$http.post(this.UMS_URL + '/sell/seller/sellerDurationBuy', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.push({ path: '/seller/own' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.duration {
  width: 96%;
  margin: 10px auto;
  border: 1px solid #eee;
  border-radius: 5px;
  height: 60px;
  text-align: left;
  .name {
    padding-top: 10px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    height: 25px;
    line-height: 25px;
  }
  .price {
    text-align: center;
    padding-top: 10px;
    font-size: 13px;
    font-weight: 600;
    height: 25px;
    line-height: 25px;
  }
  .old-price {
    text-align: center;
    text-decoration: line-through;
    color: #bbb;
    height: 25px;
    line-height: 25px;
  }
  .desc {
    font-size: 11px;
    text-align: center;
    height: 25px;
    line-height: 25px;
  }
  .radio {
    margin-top: 20px;
  }
  .icon {
    margin: 2px 8px;
    width: 40px;
    height: 40px;
  }
}
.title {
  border-bottom: 1px solid #eee;
  .text {
    height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 14px;
    font-weight: 800;
    padding-left: 10px;
  }
  .btn {
    height: 30px;
    line-height: 30px;
    text-align: right;
    padding-right: 20px;
  }
}
.pay {
  width: 96%;
  margin: 10px auto;
  border: 1px solid #eee;
  border-radius: 5px;
  height: 60px;
  text-align: left;
  .name {
    padding-top: 10px;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    line-height: 40px;
  }
  .price {
    padding-top: 10px;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
  }
  .radio {
    margin-top: 20px;
  }
  .icon {
    height: 35px;
    width: 100%;
    margin-top: 10px;
  }
}
.button {
  margin-top: 20px;
}
</style>
